import arabesqueAi from '@aether/ui/app-shared/assets/arabesque-ai.svg';
import { ButtonNew } from '@aether/ui/ButtonNew';
import { DropdownMenu } from '@aether/ui/DropdownMenu';
import { IconButton } from '@aether/ui/IconButton';
import { cn } from '@aether/ui/utils';
import { getAppOrigin } from '@aether/utils/services/linking-service';
import { Link, useNavigate } from 'react-router-dom';

export function Header({
  children,
  origin = '',
  isAuthenticated,
  className,
}: {
  children?: React.ReactNode;
  origin: string | null;
  isAuthenticated: boolean;
  className?: string;
}) {
  // accounts for host being null
  if (!origin) origin = '';
  return (
    <div className={cn('grid w-full py-2 sm:grid-cols-[1fr_auto] md:text-left', className)}>
      <nav>
        <div className="mr-2 inline-block">
          <div className="mr-2 inline-block align-middle">
            <MainMenu />
          </div>
          <Link to="/">
            <img src="/images/dragon.svg" alt="Arabesque AI" className="mr-2 inline-block h-8" />
            <div className="inline-grid items-baseline lg:grid-cols-2">
              <div className="mr-2 whitespace-nowrap text-xl font-bold">Portfolio Dragon</div>
              <div className="inline-block whitespace-nowrap text-xs text-gray-400">
                Powered by <img src={arabesqueAi} alt="arabesque.ai" className="ml-1 inline-block h-3.5" />
              </div>
            </div>
            <div className="sr-only">Home</div>
          </Link>
        </div>
        {children && <div className="inline-block">{children}</div>}
      </nav>
      <UserMenu isAuthenticated={isAuthenticated} origin={origin} />
    </div>
  );
}

function UserMenu({ isAuthenticated, origin }: { isAuthenticated: boolean; origin: string }) {
  const appHost = getAppOrigin(origin) || '';

  if (!isAuthenticated)
    return (
      <div className="hidden space-x-4 sm:flex">
        <ButtonNew variant="primary" iconBefore="ic:outline-add" asChild>
          <Link to={`${appHost}/auth/register`}>Create Account</Link>
        </ButtonNew>
        <ButtonNew variant="secondary" iconBefore="ic:outline-edit" asChild>
          <Link to={`${appHost}/auth/login`}>Sign In</Link>
        </ButtonNew>
      </div>
    );

  return (
    <ButtonNew variant="primary" iconAfter="ic:outline-arrow-forward" asChild>
      <Link to={appHost}>Dashboard</Link>
    </ButtonNew>
  );
}

function MainMenu() {
  const navigate = useNavigate();
  return (
    <div>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <IconButton size="medium" icon="ic:menu" variant="ghost" />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="z-20" align="start" side="bottom" variant="menu">
          <DropdownMenu.Item variant="menu" onSelect={() => navigate('/')}>
            Home
          </DropdownMenu.Item>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger variant="menu">About the Arabesque Group</DropdownMenu.SubTrigger>
            <DropdownMenu.SubContent variant="menu">
              <Link to="https://www.arabesque.com/ai/about-us/">
                <DropdownMenu.Item variant="menu">About Us</DropdownMenu.Item>
              </Link>
              <Link to="https://www.arabesque.com/about-us/the-arabesque-group/">
                <DropdownMenu.Item variant="menu">The Arabesque Group</DropdownMenu.Item>
              </Link>
              <Link to="https://www.arabesque.com/board-members/">
                <DropdownMenu.Item variant="menu">Our Board</DropdownMenu.Item>
              </Link>
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger variant="menu">Services</DropdownMenu.SubTrigger>
            <DropdownMenu.SubContent variant="menu">
              <Link to="https://www.arabesque.com/ai/platform/#capabilities">
                <DropdownMenu.Item variant="menu">Portfolio Dragon</DropdownMenu.Item>
              </Link>
              <Link to="https://www.esgbook.com/">
                <DropdownMenu.Item variant="menu">ESG Book</DropdownMenu.Item>
              </Link>
              <Link to="https://www.arabesque.com/fund-centre/">
                <DropdownMenu.Item variant="menu">Fund Center</DropdownMenu.Item>
              </Link>
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
          <Link to="https://www.arabesque.com/ai/ai-insights/">
            <DropdownMenu.Item variant="menu">Insights</DropdownMenu.Item>
          </Link>
          <Link to="https://www.arabesque.com/careers/">
            <DropdownMenu.Item variant="menu">Careers</DropdownMenu.Item>
          </Link>
          <Link to="https://www.arabesque.com/ai/contact-us/">
            <DropdownMenu.Item variant="menu">Contact Us</DropdownMenu.Item>
          </Link>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
}
